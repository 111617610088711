import { assign, cloneDeep, keys } from "lodash";
import { request } from "@/api/service";
export default {
  code: "serviceToPage",
  name: "服务数据转换页面信息",
  desc: "服务数据转换页面信息",
  // eslint-disable-next-line complexity
  func: async function(id, callback) {
    if (this.pageForm.saleInfo && this.pageContext.info) {
      this.pageForm.saleInfo.saleCode = this.pageContext.info.userId;
      this.pageForm.saleInfo.saleName = this.pageContext.info.alias;
      this.pageForm.saleInfo.saleDealerId = this.pageContext.info.orgId;
      this.pageForm.saleInfo.saleDealerName = this.pageContext.info.orgName;
    }
    if (!id) {
      return null;
    }
    const res = await request({
      url: `/afis-carservice/contract/order/info/${id}`,
      method: "get"
    });
    this.pageOrder = res;
    const { customer, vehicle, coverages, images, orderExt, saleInfo } = res;
    if (this.pageCache) {
      this.pageCache.initFlag = false;
    }
    // 畅享代步：修改时校验“合同生效日期”是否为当前以后日期（不包括当前日期），如果符合条件，自动带出已选的合同生效日期；如果不符合，将此字段清空
    if (res.coverages && res.coverages.length && res.coverages[0].startDate <= Date.now()) {
      res.coverages[0].startDate = null;
    }
    if (id) {
      this.pageForm.id = id;
      if (res.orderStatus === "3" && this.pageContext.pageData) {
        // 状态为退回修改，不能暂存操作
        this.pageContext.pageData.storageFlag = false;
      }
    }
    if (customer) {
      assign(this.pageForm.customer, customer);
      if (customer.provinceName) {
        this.pageForm.customer.areaCode = [customer.provinceCode, customer.cityCode, customer.countyCode];
      }
    }
    if (vehicle) {
      assign(this.pageForm.vehicle, vehicle);
    }
    if (res.comboCode) {
      this.pageForm.combo = {
        comboCode: res.comboCode,
        comboName: res.comboName
      };
    }
    // 初始化服务信息列表
    if (coverages) {
      let formCoverages = cloneDeep(coverages);
      formCoverages && formCoverages.map(coverage => {
        this.productFormItem[coverage.productCode].checked = true;
        keys(this.productFormItem[coverage.productCode].form).map(fk => {
          this.productFormItem[coverage.productCode].form[fk] = coverage[fk];
          this.productFormItem[coverage.productCode].coverageAmount = coverage.coverageAmount;
          this.productFormItem[coverage.productCode].productFeeId = coverage.productFeeId;
          this.productFormItem[coverage.productCode].productFeeCode = coverage.productFeeCode;
          this.productFormItem[coverage.productCode].productCode = coverage.productCode;
          this.productFormItem[coverage.productCode].productName = coverage.productName;
          this.productFormItem[coverage.productCode].costAmount = coverage.costAmount;
          this.productFormItem[coverage.productCode].orderPrice = coverage.orderPrice;
          this.handleBuyProduct && this.handleBuyProduct(coverage.productCode);
        });
      });
    }
    if (images && images.length) {
      const isImg = function(fileItem) {
        if (!fileItem.fileName) {
          return false;
        }
        const suffix = fileItem.fileName.substring(fileItem.fileName.lastIndexOf(".") + 1);
        if (".jpg.png.jpeg.jif.jfif".indexOf(suffix) !== -1) {
          return true;
        }
        return false;
      };
      const imageObj = {};
      images.forEach(image => {
        image.isImage = isImg(image);
        image.url = image.filePath;
        if (imageObj[image.fieldCode]) {
          imageObj[image.fieldCode].push(image);
        } else {
          imageObj[image.fieldCode] = [image];
        }
      });
      this.pageForm.images = imageObj;
    }
    if (orderExt) {
      assign(this.pageForm.orderExt, orderExt);
    }
    if (saleInfo) {
      assign(this.pageForm.saleInfo, saleInfo);
    }
    callback && callback(res);
    return res;
  }
};
