export default {
  code: "calcCarAge",
  name: "计算产品【二手车车龄】",
  desc: "计算产品【二手车车龄】",
  func: function() {
    const getMonthNumber = (date1, date2) => {
      const year1 = new Date(date1).getFullYear();
      const year2 = new Date(date2).getFullYear();
      const month1 = new Date(date1).getMonth() + 1;
      const month2 = new Date(date2).getMonth() + 1;
      let len = (year2 - year1) * 12 + (month2 - month1);
      const day = new Date(date2).getDate() - new Date(date1).getDate();
      if (day > 0) {
        len += 1;
      }
      return len;
    };
    if (this.pageForm.vehicle.registerDate) {
      const val = getMonthNumber(this.pageForm.vehicle.registerDate, new Date().getTime());
      this.pageForm.vehicle.carAge = val > 0 ? val : 0;
    } else {
      this.pageForm.vehicle.carAge = 0;
    }
  }
};
