import { get } from "lodash";
import { request } from "@/api/service";
export default {
  code: "queryModelCodeInfo",
  name: "查询新车指导价",
  desc: "查询新车指导价",
  func: function(path) {
    const config = get(this.pageLayoutMap, path, null);
    this.pageForm.vehicle.modelCode &&
      request({
        url: "/afis-product/cmc/carModel/infoByCode/" + this.pageForm.vehicle.modelCode,
        method: "get"
      }).then(res => {
        if (res && res.msrp) {
          config.val = res.msrp;
          this.pageForm.vehicle.invoicePrice = res.msrp;
        }
      });
  }
};
