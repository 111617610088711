import { getDictInfo } from "@/api/common/dict";
import { request } from "@/api/service";
import { omit, keys, assign } from "lodash";
export default {
  label: "设置服务信息下拉选项",
  code: "setCoverageOptions",
  func: async function(dictCode, targetAttr, productCode) {
    let ctx = this.context || this;
    try {
      if (dictCode && targetAttr && productCode) {
        let options = [];
        let formParam = {};
        keys(ctx.pageForm).map(k => {
          assign(formParam, ctx.pageForm[k]);
        });
        const res = await request({
          url: "/afis-carservice/prd/feeCfg/dict/factor/item",
          method: "post",
          data: {
            ...omit(ctx.productFormItem[productCode].form, [targetAttr, "productName"]),
            ...formParam,
            comboCode: ctx.productFormItem[productCode].isCombo === "1" ? formParam.comboCode : null,
            productCode,
            targetAttr
          }
        });
        const dicts = await getDictInfo(dictCode);
        const vs = (res || []).map(item => item.value);
        options = dicts.filter(item => vs.includes(item.value));
        // 设置下拉选项
        ctx.productFormItem[productCode].config.formItems.map(fi => {
          if (fi.prop === targetAttr) {
            fi.options = options;
          }
        });
        const values = options.map(item => item.value);
        if (values.length === 1) {
          ctx.productFormItem[productCode].form[targetAttr] = values[0];
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
};
