import { request } from "@/api/service";
import Common from "@/utils/common";
import { isArray, cloneDeep, keys } from "lodash";
import setting from "@/setting";
export default {
  code: "queryCombo",
  name: "查询套餐",
  desc: "通过套餐查询其服务配置及详情并设置套餐名称冗余字段",
  func: async function() {
    let productCodes = keys(this.context.productFormItem);
    if (this.pageForm.combo && !this.pageForm.combo.comboCode) {
      keys(this.pageForm.coverages).map(ck => {
        let coverage = this.pageForm.coverages[ck];
        coverage.disabled = false;
        if (coverage.isCombo === "1") {
          coverage.checked = false;
          coverage.isCombo = "0";
          coverage.comboCode = this.pageForm.combo.comboCode;
          coverage.costAmount = coverage.orderPrice;
        }
      });
      return;
    }
    // 查询套餐详情
    const comboRes = await request({
      url: "/afis-carservice/prd/combo/infoByCode/" + this.pageForm.combo.comboCode,
      method: "get"
    });
    const res = await request({
      url: "/organization/authorization/product/web/current/load",
      method: "post",
      data: {
        ...this.pageForm.vehicle
      }
    });
    this.context.pageContext.authProductCodes = (res || []).filter(r => setting.configProductCode.includes(r.value) && (comboRes.repel ? comboRes.repel.indexOf(r.value) === -1 : true)).map(r => r.value);
    let coverageMap = cloneDeep(this.context.productFormItem);
    res &&
      res.map(r => {
        let f =
          comboRes.comboInfos.some(c => c.productCode === r.value) &&
          Common.isArrContained(
            res.map(r => r.value),
            [...new Set(comboRes.comboInfos.map(c => c.productCode))]
          );
        let cov = {
          ...coverageMap[r.value],
          checked: f
        };
        cov.disabled = f;
        cov.isCombo = f ? "1" : "0";
        this.context.productFormItem[r.value] = cov;
        if (cov.checked) {
          this.context.handleBuyProduct(r.value);
        }
      });
  }
};
