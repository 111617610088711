import { get } from "lodash";
import dayjs from "dayjs";
export default {
  code: "setNewCarFlag",
  name: "设置车辆类型",
  desc: "设置车辆类型",
  func: function(path) {
    const config = get(this.pageLayoutMap, path, null);
    if (this.pageForm["vehicle"].registerDate) {
      const getMonthNumber = (date1, date2) => {
        const year1 = new Date(date1).getFullYear();
        const year2 = new Date(date2).getFullYear();
        const month1 = new Date(date1).getMonth() + 1;
        const month2 = new Date(date2).getMonth() + 1;
        let len = (year2 - year1) * 12 + (month2 - month1);
        const day = new Date(date2).getDate() - new Date(date1).getDate();
        if (day > 0) {
          len += 1;
        }
        return len;
      };
      let month = getMonthNumber(this.pageForm["vehicle"].registerDate, new Date().getTime());
      if (month <= 1) {
        this.pageForm["vehicle"].newCarFlag = "1";
        config.val = "1";
      } else if (month > 1) {
        this.pageForm["vehicle"].newCarFlag = "2";
        config.val = "2";
      }
    }
    if (this.pageForm["coverages"] && this.pageForm["coverages"].length) {
      this.pageForm["coverages"].some(c => {
        if (c.checked && c.productCode === "FTDCPB") {
          // this.calcProductAmount('FTDCPB', c)
        }
      });
    }
  }
};
